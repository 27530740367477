import jwtDecode from "jwt-decode";
import store from "@/store";

export default () => {
	try {
		const accessToken = store.getters["auth/accessToken"];
		const { exp } = jwtDecode(accessToken);
		const accessTokenExp = (exp - 30) * 1000;
		return accessTokenExp < Date.now();
	} catch (error) {
		console.debug(error);
	}
};
