import { axiosInstance } from "@/config/axios";
import xss from "xss";

export default {
	namespaced: true,
	state: {
		feedsLimit: 10,
		feedsLoad: false,
		feeds: { data: [], meta: { results_count: 0 } },
		feed: {},
	},
	getters: {
		feedsLimit: ({ feedsLimit }) => feedsLimit,
		feedsLoad: ({ feedsLoad }) => feedsLoad,
		feeds: ({ feeds }) => feeds,
		feed: ({ feed }) => feed,
	},
	mutations: {
		setFeedsLoad(state, payload) {
			state.feedsLoad = payload;
		},
		setFeeds(state, payload) {
			state.feeds = payload;
		},
		setFeed(state, payload) {
			state.feed = payload;
		},
		addFeed(state, payload) {
			state.feeds.meta.results_count++;
			state.feeds.data.unshift(payload);
		},
		updateFeed(state, payload) {
			state.feeds.data = state.feeds.data.map((feed) => {
				if (feed.id === payload.id) {
					feed = payload;
				}
				return feed;
			});
		},
		deleteFeedById(state, id) {
			state.feeds.data = state.feeds.data.filter((feed) => {
				return id !== feed.id;
			});
			state.feeds.meta.results_count--;
		},
	},
	actions: {
		async fetchFeeds(
			{ commit, getters: { feedsLimit } },
			{ page = 1, query } = {}
		) {
			try {
				commit("setFeedsLoad", true);
				console.log(query)
				if (query && !Object.keys(query).length) {
					query = { order_by: "updated_at", order_type: "DESC" };
				}
				let limit = feedsLimit
				if (query.limit) {
					limit = query.limit
					delete query.limit
				}
				let offset = 0,
					currentPage = page - 1;

				if (currentPage) {
					offset = currentPage * limit;
				}

				let queryString = "";

				if (query && Object.keys(query).length) {
					const keys = Object.keys(query);

					keys.forEach((key) => {
						if (!query[key]) {
							delete query[key];
						}
					});

					queryString = "&" + new URLSearchParams(query).toString();
				}

				const { data } = await (await axiosInstance()).get(
					`feeds?limit=${limit}&offset=${offset}${queryString}`
				);
				commit("setFeeds", data);
			} catch (error) {
				console.debug(error);
				return Promise.reject(error);
			} finally {
				commit("setFeedsLoad", false);
			}
		},
		async fetchFeedById({ commit, getters }, feedId) {
			try {
				commit("setFeedsLoad", true);

				let feed;

				const cache = getters.feeds.data.filter((feed) => {
					return feed.id === feedId;
				});

				if (cache.length) {
					feed = cache[0];
				} else {
					const { data } = await (await axiosInstance()).get(`feeds/${encodeURIComponent(feedId)}`);
					feed = data;
				}
				return feed;
			} catch (error) {
				console.debug(error);
				return Promise.reject(error);
			} finally {
				commit("setFeedsLoad", false);
			}
		},
		async createFeed({ commit }, payload) {
			try {
				commit("setFeedsLoad", true);
				const { data, status } = await (await axiosInstance()).post("feeds", {
					...payload,
					name: xss(payload.name),
				});

				if (status === 201) commit("addFeed", data);
			} catch (error) {
				console.debug(error);
				return Promise.reject(error);
			} finally {
				commit("setFeedsLoad", false);
			}
		},
		async updateFeedById({ commit }, { feedId, payload }) {
			try {
				commit("setFeedsLoad", true);
				/* eslint-disable */
				const {
					id,
					user_id,
					created_at,
					updated_at,
					sources_count,
					posts_count,
					posts_count_24h,
					posts_count_48h,
					...feed
				} = payload;
				/* eslint-disable */
				const { data } = await (await axiosInstance()).patch(
					`feeds/${encodeURIComponent(feedId)}`,
					{
						...feed,
						name: !payload.name ? undefined : xss(payload.name),
					}
				);
				commit("updateFeed", data);
			} catch (error) {
				console.debug(error);
				return Promise.reject(error);
			} finally {
				commit("setFeedsLoad", false);
			}
		},
		async deleteFeedById({ commit }, { id }) {
			try {
				commit("setFeedsLoad", true);
				const { data } = await (await axiosInstance()).delete(
					`feeds/${encodeURIComponent(id)}`
				);
				commit("deleteFeedById", data.id);
			} catch (error) {
				console.debug(error);
				return Promise.reject(error);
			} finally {
				commit("setFeedsLoad", false);
			}
		},
	},
};
