import { axiosInstance } from "@/config/axios";

export default {
	namespaced: true,
	state: {
		postsLimit: 10,
		postsLoad: false,
		posts: {
			data: [],
			meta: {
				results_count: 0,
				filters: {
					keywords: [],
					source: [],
					source_uid: [],
				},
			},
		},
	},
	getters: {
		posts: ({ posts }) => posts,
		postsLimit: ({ postsLimit }) => postsLimit,
		postsLoad: ({ postsLoad }) => postsLoad,
		sources: ({
			posts: {
				meta: {
					filters: { source_uid },
				},
			},
		}) =>
			source_uid.map(({ extended, qty }) => ({
				...extended,
				qty,
			})),
	},
	mutations: {
		setPosts(
			state,
			payload = {
				data: [],
				meta: {
					results_count: 0,
					filters: {
						keywords: [],
						source: [],
						source_uid: [],
					},
				},
			}
		) {
			state.posts = payload;
		},
		setPostsData(state, payload) {
			state.posts.data = payload;
		},
		addPostsData(state, payload) {
			state.posts.data = state.posts.data.concat(payload);
		},
		setPostsLoad(state, payload) {
			state.postsLoad = payload;
		},
		setCounterOfNewPosts(state, payload) {
			state.counterOfNewPosts = payload;
		},
	},
	actions: {
		async fetchPosts(
			{ commit, rootGetters },
			{ feedId, query, withSetData = true, withOffset = false } = {}
		) {
			try {
				if (withSetData) commit("setPostsLoad", true);

				if (query && !Object.keys(query).length) {
					query = { order_by: "cdate", order_type: "DESC" };
				}

				if (query && Object.keys(query).length) {
					const keys = Object.keys(query);
					keys.forEach((key) => {
						if (!query[key]) {
							delete query[key];
						}
					});
				}
				if (query.keywords && !Array.isArray(query.keywords)) {
					query.keywords = [query.keywords];
				}

				if (query.source_group_id && !Array.isArray(query.source_group_id)) {
					query.source_group_id = [query.source_group_id];
				}

				if (query.source && !Array.isArray(query.source)) {
					query.source = [query.source];
				}
				const highlightKeywordsEnabled = rootGetters?.['users/currentUserProfile']?.settings?.feed?.['highlight_keywords_enabled']
                                if (highlightKeywordsEnabled) {
					query['highlight_keywords_enabled'] = true;
					query['highlight_pre_tag'] = '<span class=highlight>';
					query['highlight_post_tag'] = '</span>';

				}
				const { data } = await (await axiosInstance()).post(
					`feeds/${encodeURIComponent(feedId)}/posts/search`,
					query
				);

				if (withOffset) {
					commit("addPostsData", data.data);
				} else if (withSetData) {
					commit("setPosts", data);
				}

				return data;
			} catch (error) {
				console.debug(error);
				return Promise.reject(error);
			} finally {
				if (withSetData) commit("setPostsLoad", false);
			}
		},
		async fetchPostById({ commit, getters }, postId) {
			try {
				commit("setPostsLoad", true);
				let post;
				const cache = getters.posts.data.filter((post) => {
					return post.id === postId;
				});

				if (cache.length) {
					post = cache[0];
				} else {
					const { data } = await (await axiosInstance()).get(
						`feeds/posts/${encodeURIComponent(postId)}`
					);
					post = data;
				}
				return post;
			} catch (error) {
				console.debug(error);
				return Promise.reject(error);
			} finally {
				commit("setPostsLoad", false);
			}
		},
    async exportDocx ({ commit }, postIds) {
      try {
        const data = await (await axiosInstance()).post(`export/posts/docx`, {
          post_ids: postIds
        })
        console.log(data)
      } catch (error) {
        commit()
        console.debug(error)
      }
    }
	},
};
