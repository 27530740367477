import { axiosInstance } from "@/config/axios";

export default {
	namespaced: true,
	state: { organizationsLoad: false, organizations: [] },
	getters: {
		organizations: ({ organizations }) => organizations,
		organizationsLoad: ({ organizationsLoad }) => organizationsLoad,
	},
	mutations: {
		setOrganizations(state, payload) {
			state.organizations = payload;
		},
		addOrganization(state, payload) {
			state.organizations.unshift(payload);
		},
		updateOrganization(state, payload) {
			state.organizations = state.organizations.map((organization) => {
				if (organization.id === payload.id) {
					organization = payload;
				}
				return organization;
			});
		},
		deleteOrganizationById(state, id) {
			state.organizations = state.organizations.filter((organization) => {
				return id !== organization.id;
			});
		},
		setOrganizationsLoad(state, payload) {
			state.organizationsLoad = payload;
		},
	},
	actions: {
		async fetchOrganizations({ commit }) {
			try {
				commit("setOrganizationsLoad", true);
				const { data } = await (await axiosInstance()).get("organizations");
				commit("setOrganizations", data);
			} catch (error) {
				console.debug(error);
				return Promise.reject(error);
			} finally {
				commit("setOrganizationsLoad", false);
			}
		},
		async fetchOrganizationById({ commit, getters }, organizationId) {
			try {
				commit("setOrganizationsLoad", true);

				let organization;

				const cache = getters.organizations.filter((organization) => {
					return organization.id === organizationId;
				});

				if (cache.length) {
					organization = cache[0];
				} else {
					const { data } = await (await axiosInstance()).get(
						`organizations/${encodeURIComponent(organizationId)}`
					);
					organization = data;
				}
				return organization;
			} catch (error) {
				console.debug(error);
				return Promise.reject(error);
			} finally {
				commit("setOrganizationsLoad", false);
			}
		},
		async createOrganization({ commit }, payload) {
			try {
				commit("setOrganizationsLoad", true);

				const { data, status } = await (await axiosInstance()).post("organizations", {
					active: false,
					...payload,
				});

				if (status === 201) commit("addOrganization", data);
			} catch (error) {
				console.debug(error);
				return Promise.reject(error);
			} finally {
				commit("setOrganizationsLoad", false);
			}
		},
		async updateOrganizationById({ commit }) {
			try {
				const { organizationId, payload } = arguments[1];
				const { id, organizations_count, ...organization } = payload; // eslint-disable-line
				const { data } = await (await axiosInstance()).patch(
					`organizations/${encodeURIComponent(organizationId)}`,
					{
						active: false,
						...organization,
					}
				);
				commit("updateOrganization", data);
			} catch (error) {
				console.debug(error);
				return Promise.reject(error);
			}
		},
		async deleteOrganizationById({ commit }, { id }) {
			try {
				commit("setOrganizationsLoad", true);
				await (await axiosInstance()).delete(`organizations/${encodeURIComponent(id)}`);
				commit("deleteOrganizationById", id);
			} catch (error) {
				console.debug(error);
				return Promise.reject(error);
			} finally {
				commit("setOrganizationsLoad", false);
			}
		},
	},
};
