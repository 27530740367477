import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import createMutationsSharer from "vuex-shared-mutations";
import auth from "@/store/modules/auth";
import users from "@/store/modules/users";
import feeds from "@/store/modules/feeds";
import keywords from "@/store/modules/keywords";
import sources from "@/store/modules/sources";
import posts from "@/store/modules/posts";
import organizations from "@/store/modules/organizations";
import telegram from "@/store/modules/telegram";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
	storage: window.localStorage,
	modules: ["auth", "users"],
	filter: ({ type }) =>
		type === "auth/setAccessToken" ||
        type === "users/setCurrentUserProfile"
});

export default new Vuex.Store({
	state: { error: {}, axiosLock: false },
	getters: {
		error: ({ error }) => error,
		axiosLock: ({ axiosLock }) => axiosLock,
	},
	mutations: {
		setError(state, payload = {}) {
			state.error = payload;
		},
		setAxiosLock(state, payload) {
			state.axiosLock = payload;
		},
	},
	modules: { auth, users, feeds, keywords, sources, posts, organizations, telegram },
	plugins: [
		vuexLocal.plugin,
		createMutationsSharer({
			predicate: [
				"setAxiosLock",
				"auth/setAccessToken"
			]
		})
	]
});
