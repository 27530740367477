<template>
	<div id="app">
		<Banner />
		<Nav v-if="!isAuthRoute" />
		<router-view />
	</div>
</template>
<script>
import Nav from "@/components/Nav";
import Banner from "@/components/Banner";
export default {
	name: "App",
	components: { Nav, Banner },
	computed: {
		isAuthRoute() {
			return this.$route.name === "Auth";
		},
	},
};
</script>
