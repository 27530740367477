import { axiosInstance } from "@/config/axios";
import xss from "xss";

export default {
	namespaced: true,
	state: {
		keywords: {
			data: [],
			meta: { results_count: 0 },
		},
		keywordsLimit: 10,
		keywordsLoad: false,
		keywordsUploadError: [],
		keywordsUploadCounter: 0,
		keywordsUpload: false,
	},
	getters: {
		keywords: ({ keywords }) => keywords,
		keywordsLimit: ({ keywordsLimit }) => keywordsLimit,
		keywordsLoad: ({ keywordsLoad }) => keywordsLoad,
		keywordsUpload: ({ keywordsUpload }) => keywordsUpload,
		keywordsUploadError: ({ keywordsUploadError }) => keywordsUploadError,
		keywordsUploadCounter: ({ keywordsUploadCounter }) => keywordsUploadCounter,
	},
	mutations: {
		setKeywords(state, payload) {
			state.keywords = payload;
		},
		addKeyword(state, payload) {
			state.keywords.meta.results_count++;
			state.keywords.data.unshift(payload);
		},
		deleteKeywordById(state, id) {
			state.keywords.data = state.keywords.data.filter((keyword) => {
				return id !== keyword.id;
			});
			state.keywords.meta.results_count--;
		},
		setKeywordsLoad(state, payload) {
			state.keywordsLoad = payload;
		},
		setKeywordsUploadError(state, payload) {
			state.keywordsUploadError.unshift(payload);
		},
		setKeywordsUpload(state, payload) {
			state.keywordsUpload = payload;
		},
		setKeywordsUploadCounter(state) {
			state.keywordsUploadCounter = state.keywordsUploadCounter + 1;
		},
		clearKeywordsUploadError(state) {
			state.keywordsUploadError = [];
		},
		clearKeywordsUploadCounter(state) {
			state.keywordsUploadCounter = 0;
		},
	},
	actions: {
		async fetchKeywords(
			{ commit, getters: { keywordsLimit } },
			{ page = 1, query = {} } = {}
		) {
			try {
				commit("setKeywords");
				commit("setKeywordsLoad", true);
				let offset = 0,
					currentPage = page - 1;

				if (currentPage) {
					offset = currentPage * keywordsLimit;
				}

				let queryString = "";

				if (query && Object.keys(query).length) {
					const keys = Object.keys(query);

					keys.forEach((key) => {
						if (!query[key]) {
							delete query[key];
						}
					});

					queryString = "&" + new URLSearchParams(query).toString();
				}

				const { data } = await (await axiosInstance()).get(
					`keywords?limit=${keywordsLimit}&offset=${offset}${queryString}`
				);
				commit("setKeywords", data);
			} catch (error) {
				console.debug(error);
				return Promise.reject(error);
			} finally {
				commit("setKeywordsLoad", false);
			}
		},
		async createKeyword({ commit }, { value = "", withError = true } = {}) {
			try {
				commit("setKeywordsLoad", true);

				const { data, status } = await (await axiosInstance({ withError })).post(
					"keywords",
					{
						value: xss(value).trim(),
					}
				);
				if (status === 201) commit("addKeyword", data);
			} catch (error) {
				console.debug(error);
				return Promise.reject(error);
			} finally {
				commit("setKeywordsLoad", false);
			}
		},
		async uploadKeywords({ dispatch, commit, getters }, keywords) {
			try {
				const load = [...new Set(keywords)]
					.reduce((accumulator, keyword) => {
						const copy = getters.keywords.data.filter(
							({ value }) => value === keyword.trim()
						);

						if (!copy.length && keyword.trim()) {
							accumulator.unshift(keyword);
						}

						return accumulator;
					}, [])
					.map((keyword) => {
						return new Promise((resolve, reject) => {
							dispatch("createKeyword", { value: keyword, withError: false })
								.then(() => {
									commit("setKeywordsUploadCounter");

									resolve();
								})
								.catch((error) => {
									commit("setKeywordsUploadError", keyword);

									reject(error);
								});
						});
					});

				commit("clearKeywordsUploadError");
				commit("clearKeywordsUploadCounter");
				commit("setKeywordsUpload", true);
				commit("setKeywordsLoad", true);

				await Promise.allSettled(load);
			} catch (error) {
				console.debug(error);
				return Promise.reject(error);
			} finally {
				commit("setKeywordsUpload", false);
				commit("setKeywordsLoad", false);
			}
		},
		async deleteKeywordById({ commit }, { value }) {
			try {
				commit("setKeywordsLoad", true);
				const { data } = await (await axiosInstance()).delete(
					`keywords/${encodeURIComponent(value)}`
				);
				commit("deleteKeywordById", data.id);
			} catch (error) {
				console.debug(error);
				return Promise.reject(error);
			} finally {
				commit("setKeywordsLoad", false);
			}
		},
	},
};
