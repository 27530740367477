import { axiosInstance } from "@/config/axios";

export default {
	namespaced: true,
	state: { currentUserProfile: {}, usersLoad: false, users: [] },
	getters: {
		currentUserProfile: ({ currentUserProfile }) => currentUserProfile,
		users: ({ users }) => users,
		usersLoad: ({ usersLoad }) => usersLoad,
	},
	mutations: {
		setCurrentUserProfile(state, payload = {}) {
			state.currentUserProfile = payload;
		},
		setUsers(state, payload) {
			state.users = payload;
		},
		addUser(state, payload) {
			state.users.unshift(payload);
		},
		updateUser(state, payload) {
			state.users = state.users.map((user) => {
				if (user.id === payload.id) {
					user = payload;
				}
				return user;
			});
		},
		deleteUserById(state, id) {
			state.users = state.users.filter((user) => {
				return id !== user.id;
			});
		},
		setUsersLoad(state, payload) {
			state.usersLoad = payload;
		},
	},
	actions: {
		async fetchCurrentUserProfile({ commit }) {
			try {
				const { data } = await (await axiosInstance()).get("users/@me");
				commit("setCurrentUserProfile", data);
			} catch (error) {
				console.debug(error);
				return Promise.reject(error);
			}
		},
		async fetchUsers({ commit }) {
			try {
				commit("setUsersLoad", true);
				const { data } = await (await axiosInstance()).get("users");
				commit("setUsers", data);
			} catch (error) {
				console.debug(error);
				return Promise.reject(error);
			} finally {
				commit("setUsersLoad", false);
			}
		},
		async fetchOrganizationUsers({ commit }, organizationId) {
			try {
				commit("setUsersLoad", true);
				const { data } = await (await axiosInstance()).get(`organizations/${encodeURIComponent(organizationId)}/users`);
				commit("setUsers", data);
			} catch (error) {
				console.debug(error);
				return Promise.reject(error);
			} finally {
				commit("setUsersLoad", false);
			}
		},
		async fetchUserById({ commit, getters }, userId) {
			try {
				commit("setUsersLoad", true);

				let user;

				const cache = getters.users.filter((user) => {
					return user.id === userId;
				});

				if (cache.length) {
					user = cache[0];
				} else {
					const { data } = await (await axiosInstance()).get(
						`users/${encodeURIComponent(userId)}`
					);
					user = data;
				}
				return user;
			} catch (error) {
				console.debug(error);
				return Promise.reject(error);
			} finally {
				commit("setUsersLoad", false);
			}
		},
		async createUser({ commit }, payload) {
			try {
				commit("setUsersLoad", true);

				const { data, status } = await (await axiosInstance()).post("users", {
					active: false,
					...payload,
				});

				if (status === 201) commit("addUser", data);
			} catch (error) {
				console.debug(error);
				return Promise.reject(error);
			} finally {
				commit("setUsersLoad", false);
			}
		},
		async updateUserById({ commit }) {
			try {
				const { userId, payload } = arguments[1];
				const { id, feeds_count, ...user } = payload; // eslint-disable-line
				const { data } = await (await axiosInstance()).patch(
					`users/${encodeURIComponent(userId)}`,
					{
						active: false,
						...user,
					}
				);
				commit("updateUser", data);
			} catch (error) {
				console.debug(error);
				return Promise.reject(error);
			}
		},
		async deleteUserById({ commit }, { id }) {
			try {
				commit("setUsersLoad", true);
				await (await axiosInstance()).delete(`users/${encodeURIComponent(id)}`);
				commit("deleteUserById", id);
			} catch (error) {
				console.debug(error);
				return Promise.reject(error);
			} finally {
				commit("setUsersLoad", false);
			}
		},
	},
};
