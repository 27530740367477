<template>
    <div class="relative inline-block text-left menu">
        <div>
            <span
                class="inline-flex w-full justify-center text-base font-medium text-gray-500 hover:text-gray-900 cursor-pointer">
                {{ name }}
            </span>
        </div>

        <!--
    Dropdown menu, show/hide based on menu state.

    Entering: "transition ease-out duration-100"
      From: "transform opacity-0 scale-95"
      To: "transform opacity-100 scale-100"
    Leaving: "transition ease-in duration-75"
      From: "transform opacity-100 scale-100"
      To: "transform opacity-0 scale-95"
  -->
        <div class="absolute right-0 z-10 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none hidden menu-button"
            role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
            <div class="py-1" role="none">
                <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
                <router-link v-for="entry in entries" :key="entryName(entry)" :to="entryRoute(entry)"
                    class="text-gray-700 block px-4 py-2 text-base hover:bg-gray-100" role="menuitem" tabindex="-1" id="menu-item-0">{{
                            entryName(entry)
                    }}</router-link>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        name: {
            type: String
        },
        entries: {
            type: Array
        }
    },
    setup: () => {
        const entryName = entry => entry?.name
        const entryRoute = entry => entry?.route
        return {
            entryName,
            entryRoute
        }
    }
}
</script>
<style scoped>
.menu:hover .menu-button,
.menu-button:hover {
    display: block;
}
</style>