import { axiosInstance } from "@/config/axios";

export default {
	namespaced: true,
	state: {
		sources: { data: [], meta: { results_count: 0 } },
		sourcesLimit: 10,
		sourcesLoad: false,
		sourcesUpload: false,
		sourcesUploadError: [],
		sourcesUploadCounter: 0,
	},
	getters: {
		sources: ({ sources }) => sources,
		sourcesLimit: ({ sourcesLimit }) => sourcesLimit,
		sourcesLoad: ({ sourcesLoad }) => sourcesLoad,
		sourcesUpload: ({ sourcesUpload }) => sourcesUpload,
		sourcesUploadError: ({ sourcesUploadError }) => sourcesUploadError,
		sourcesUploadCounter: ({ sourcesUploadCounter }) => sourcesUploadCounter,
	},
	mutations: {
		setSources(state, payload) {
			state.sources = payload;
		},
		setSourcesLoad(state, payload) {
			state.sourcesLoad = payload;
		},
		addSource(state, payload) {
			state.sources.meta.results_count++;
			state.sources.data.unshift(payload);
		},
		deleteSourceById(state, id) {
			state.sources.data = state.sources.data.filter((source) => {
				return id !== source.id;
			});
			state.sources.meta.results_count--;
		},
		setSourcesUploadError(state, payload) {
			state.sourcesUploadError.unshift(payload);
		},
		setSourcesUpload(state, payload) {
			state.sourcesUpload = payload;
		},
		setSourcesUploadCounter(state) {
			state.sourcesUploadCounter = state.sourcesUploadCounter + 1;
		},
		clearSourcesUploadError(state) {
			state.sourcesUploadError = [];
		},
		clearSourcesUploadCounter(state) {
			state.sourcesUploadCounter = 0;
		},
	},
	actions: {
		async fetchSources(
			{ commit, getters: { sourcesLimit } },
			{ feedId, page = 1, query, limit = sourcesLimit } = {}
		) {
			try {
				commit("setSources");
				commit("setSourcesLoad", true);

				if (query && !Object.keys(query).length) {
					query = { order_by: "updated_at", order_type: "DESC" };
				}

				let offset = 0,
					currentPage = page - 1;

				if (currentPage) {
					offset = currentPage * limit;
				}

				let queryString = "";

				if (query && Object.keys(query).length) {
					const keys = Object.keys(query);
					keys.forEach((key) => {
						if (!query[key]) {
							delete query[key];
						}
					});

					queryString = "&" + new URLSearchParams(query).toString();
				}

				const { data } = await (await axiosInstance()).get(
					`feeds/${encodeURIComponent(
						feedId
					)}/sources?limit=${limit}&offset=${offset}${queryString}`
				);

				commit("setSources", data);
			} catch (error) {
				console.debug(error);
				return Promise.reject(error);
			} finally {
				commit("setSourcesLoad", false);
			}
		},
		async fetchExternalId(
			{ commit },
			{ sourceType, url, withError = true } = {}
		) {
			try {
				commit("setSourcesLoad", true);
				const { data } = await (await axiosInstance({ withError })).get(
					`../../tools/addsource?source=${sourceType}&url=${encodeURIComponent(url)}`
				);
				return data;
			} catch (error) {
				if (withError) commit("setError", { message: "not valid link" }, { root: true });
				console.debug(error);
				return Promise.reject(error);
			} finally {
				commit("setSourcesLoad", false);
			}
		},
		async createSource(
			{ dispatch, commit },
			{ feedId, payload: { type, url }, withError = true } = {}
		) {
			try {
				commit("setSourcesLoad", true);
				const {
					data: { external_id, external_name, external_source },
				} = await dispatch("fetchExternalId", {
					sourceType: type ? type : "auto",
					url,
					withError,
				});
				{
					const { data, status } = await (await axiosInstance({ withError })).post(
						`feeds/${encodeURIComponent(feedId)}/sources`,
						{
							type: external_source,
							name: external_name,
							external_id: external_id.toString(),
						}
					);
					if (status === 201) commit("addSource", data);
				}
			} catch (error) {
				console.debug(error);
				return Promise.reject(error);
			} finally {
				commit("setSourcesLoad", false);
			}
		},
		async uploadSources({ dispatch, commit }, { feedId, sources }) {
			try {
				const load = [...new Set(sources)]
					.filter((source) => {
						return source.trim();
					})
					.map((source) => {
						return new Promise((resolve, reject) => {
							dispatch("createSource", {
								feedId,
								payload: {
									url: source,
								},
								withError: false,
							})
								.then(() => {
									commit("setSourcesUploadCounter");
									resolve();
								})
								.catch((error) => {
									commit("setSourcesUploadError", source);
									reject(error);
								});
						});
					});

				commit("clearSourcesUploadError");
				commit("clearSourcesUploadCounter");
				commit("setSourcesUpload", true);
				commit("setSourcesLoad", true);

				await Promise.allSettled(load);
			} catch (error) {
				console.debug(error);
				return Promise.reject(error);
			} finally {
				commit("setSourcesUpload", false);
				commit("setSourcesLoad", false);
			}
		},
		async deleteSourceById({ commit }, { feedId, sourceId }) {
			try {
				commit("setSourcesLoad", true);
				const { data } = await (await axiosInstance()).delete(
					`feeds/${encodeURIComponent(feedId)}/sources/${encodeURIComponent(
						sourceId
					)}`
				);
				commit("deleteSourceById", data.id);
			} catch (error) {
				console.debug(error);
				return Promise.reject(error);
			} finally {
				commit("setSourcesLoad", false);
			}
		},
	},
};
