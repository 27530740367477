import VueTailwindSettings from "@/config/vue-tailwind"
import VueTailwind from "vue-tailwind";
import i18n from "@/plugins/i18n";
import { router } from "./router";
import "./assets/tailwind.css";
import "./assets/patterns.css";
import "./assets/main.css";
import App from "./App.vue";
import store from "./store";
import Vue from "vue";
import accessTokenExpired from "@/config/token-exp";
import { mapActions, mapMutations } from "vuex";

Vue.config.productionTip = false;

Vue.use(VueTailwind, VueTailwindSettings);

Vue.use(i18n);

new Vue({
	store,
	router,
	render: (h) => h(App),
	methods: {
		...mapActions("users", [
			"fetchCurrentUserProfile"
		]),
		...mapMutations("users", [
			"setCurrentUserProfile"
		])
	},
	created () {
		store.subscribe(async ({type, payload}) => {
			try {
				if (
					type === "auth/setAccessToken" &&
					!payload
				) {
					this.setCurrentUserProfile();
					router.push({
						name: "Auth"
					})
				}
				if (
					type === "auth/setAccessToken" &&
					payload &&
					!accessTokenExpired(payload)
				) {
					await this.fetchCurrentUserProfile();
					if (router.history.current.name === "Auth") {
						router.push({
							name: "Feeds"
						});
					}
				}
			} catch (error) {
				console.debug(error);				
			}
		})
	}
}).$mount("#app");
