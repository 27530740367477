import Vue from "vue";

const currentLocale = new Vue.observable({ name: "ru", data: {} });

const fetchCurrentLocaleData = async () => {
	try {
		const data = await import(`./locales/${currentLocale.name}.js`);

		currentLocale.data = data.default;
	} catch (error) {
		console.debug(error);
	}
};

fetchCurrentLocaleData();

export default (Vue) => {
	try {
		Vue.prototype.$i18n = (text, flag = 'default') => {
			try {
				let dataList = text.split('.')
				let currentText = currentLocale.data[dataList[0]]
				if (dataList.length !== 1) {
					for (let i = 1; i < dataList.length; i++){
						currentText = currentText[dataList[i]]
					}
				}
				try {
					const localeEntries = Object.entries(currentLocale.data)
					localeEntries.forEach(entry => {
						try {
							if (
								entry[0].startsWith('/') &&
								entry[0].endsWith('/')
							) {
								const entryLength = entry[0].length
								let nextEntry = entry[0].substring(1, entryLength - 1)
								const entryRegExp = new RegExp(nextEntry)
								if (entryRegExp.test(text)) {
									currentText = text.replace(entryRegExp, entry[1])
								}
							}
						} catch (error) {
							console.debug(error)
						}
					})
				} catch (error) {
					console.debug(error)
				}

				if (currentText) {

				switch (flag) {
					case 'l': {
						currentText = currentText.toLowerCase()
						break
					}
					case 'd': {
						currentText = currentText.charAt(0).toLowerCase() + currentText.slice(1);
						break
					}
					default: break
				}
					return currentText;
				}
				return text;
			} catch (error) {
				console.debug(error);
			}
		};
		Vue.prototype.$i18nSet = (name) => {
			try {
				currentLocale.name = name;
				fetchCurrentLocaleData();
			} catch (error) {
				console.debug(error);
			}
		};
	} catch (error) {
		console.debug(error);
	}
};
