import {
	TDatepicker,
	TToggle,
	TSelect,
	TAlert,
	TModal,
	TDialog,
	TPagination,
} from "vue-tailwind/dist/components";
import Ru from "vue-tailwind/dist/l10n/ru";

export default {
	"t-select": {
		component: TSelect,
		props: {
			fixedClasses:
				"block w-full pl-3 pr-10 py-2 transition duration-100 ease-in-out border rounded shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed",
			classes:
				"text-black placeholder-gray-400 bg-white border-gray-300 focus:border-blue-500 ",
			variants: {
				danger: "border-red-300 bg-red-50 placeholder-red-200 text-red-900",
				success: "border-green-300 bg-green-50 placeholder-gray-400 text-green-900",
			},
		},
	},
	"t-datepicker": {
		component: TDatepicker,
		props: {
			locale: Ru,
			fixedClasses: {
				navigator: "flex",
				navigatorViewButton: "flex items-center",
				navigatorViewButtonIcon: "flex-shrink-0 h-5 w-5",
				navigatorViewButtonBackIcon: "flex-shrink-0 h-5 w-5",
				navigatorLabel: "flex items-center py-1",
				navigatorPrevButtonIcon: "h-6 w-6 inline-flex",
				navigatorNextButtonIcon: "h-6 w-6 inline-flex",
				inputWrapper: "relative",
				viewGroup: "inline-flex flex-wrap",
				view: "w-64",
				calendarDaysWrapper: "grid grid-cols-7",
				calendarHeaderWrapper: "grid grid-cols-7",
				monthWrapper: "grid grid-cols-4",
				yearWrapper: "grid grid-cols-4",
				input:
					"block w-full px-3 py-2 transition duration-100 ease-in-out border rounded shadow-sm focus:border-blue-600 focus:ring-blue-600 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed",
				clearButton:
					"flex flex-shrink-0 items-center justify-center absolute right-0 top-0 m-2 h-6 w-6",
				clearButtonIcon: "fill-current h-3 w-3",
			},
			classes: {
				wrapper: "flex flex-col",
				dropdownWrapper: "absolute z-10",
				dropdown: "origin-top-left rounded shadow bg-white overflow-hidden mt-1",
				enterClass: "opacity-0 scale-95",
				enterActiveClass: "transition transform ease-out duration-100",
				enterToClass: "opacity-100 scale-100",
				leaveClass: "opacity-100 scale-100",
				leaveActiveClass: "transition transform ease-in duration-75",
				leaveToClass: "opacity-0 scale-95",
				inlineWrapper: "",
				inlineViews: "rounded bg-white border mt-1 inline-flex",
				inputWrapper: "",
				input: "text-black placeholder-gray-400 border-gray-300",
				clearButton:
					"hover:bg-gray-100 rounded transition duration-100 ease-in-out text-gray-600",
				clearButtonIcon: "",
				viewGroup: "",
				view: "",
				navigator: "pt-2 px-3",
				navigatorViewButton:
					"transition ease-in-out duration-100 inline-flex cursor-pointer rounded-full px-2 py-1 -ml-1 hover:bg-gray-100",
				navigatorViewButtonIcon: "fill-current text-gray-400",
				navigatorViewButtonBackIcon: "fill-current text-gray-400",
				navigatorViewButtonMonth: "text-gray-700 font-semibold",
				navigatorViewButtonYear: "text-gray-500 ml-1",
				navigatorViewButtonYearRange: "text-gray-500 ml-1",
				navigatorLabel: "py-1",
				navigatorLabelMonth: "text-gray-700 font-semibold",
				navigatorLabelYear: "text-gray-500 ml-1",
				navigatorPrevButton:
					"transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-100 rounded-full p-1 ml-2 ml-auto disabled:opacity-50 disabled:cursor-not-allowed",
				navigatorNextButton:
					"transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-100 rounded-full p-1 -mr-1 disabled:opacity-50 disabled:cursor-not-allowed",
				navigatorPrevButtonIcon: "text-gray-400",
				navigatorNextButtonIcon: "text-gray-400",
				calendarWrapper: "px-3 pt-2",
				calendarHeaderWrapper: "",
				calendarHeaderWeekDay:
					"uppercase text-xs text-gray-500 w-8 h-8 flex items-center justify-center",
				calendarDaysWrapper: "",
				calendarDaysDayWrapper: "w-full h-8 flex flex-shrink-0 items-center",
				otherMonthDay:
					"text-sm rounded-full w-8 h-8 mx-auto hover:bg-blue-100 text-gray-400 disabled:opacity-50 disabled:cursor-not-allowed",
				emptyDay: "",
				inRangeFirstDay: "text-sm bg-blue-500 text-white w-full h-8 rounded-l-full",
				inRangeLastDay: "text-sm bg-blue-500 text-white w-full h-8 rounded-r-full",
				inRangeDay:
					"text-sm bg-blue-200 w-full h-8 disabled:opacity-50 disabled:cursor-not-allowed",
				selectedDay:
					"text-sm rounded-full w-8 h-8 mx-auto bg-blue-600 text-white disabled:opacity-50 disabled:cursor-not-allowed",
				activeDay:
					"text-sm rounded-full bg-blue-100 w-8 h-8 mx-auto disabled:opacity-50 disabled:cursor-not-allowed",
				highlightedDay:
					"text-sm rounded-full bg-blue-200 w-8 h-8 mx-auto disabled:opacity-50 disabled:cursor-not-allowed",
				day:
					"text-sm rounded-full w-8 h-8 mx-auto hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed",
				today:
					"text-sm rounded-full w-8 h-8 mx-auto hover:bg-blue-100 disabled:opacity-50 disabled:cursor-not-allowed border border-blue-500",
				monthWrapper: "px-3 pt-2",
				selectedMonth: "text-sm rounded w-full h-12 mx-auto bg-blue-500 text-white",
				activeMonth: "text-sm rounded w-full h-12 mx-auto bg-blue-100",
				month: "text-sm rounded w-full h-12 mx-auto hover:bg-blue-100",
				yearWrapper: "px-3 pt-2",
				year: "text-sm rounded w-full h-12 mx-auto hover:bg-blue-100",
				selectedYear: "text-sm rounded w-full h-12 mx-auto bg-blue-500 text-white",
				activeYear: "text-sm rounded w-full h-12 mx-auto bg-blue-100",
			},
			variants: {
				danger: {
					input: "border-red-300 bg-red-50 placeholder-red-200 text-red-900",
					clearButton: "hover:bg-red-200 text-red-500",
				},
			},
		},
	},
	"t-toggle": {
		component: TToggle,
		props: {
			fixedClasses: {
				wrapper:
					"relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200",
				wrapperChecked:
					"relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200  border-2 border-transparent focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50",
				wrapperDisabled:
					"relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200 opacity-50 cursor-not-allowed",
				wrapperCheckedDisabled:
					"relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200 opacity-50 cursor-not-allowed border-2 border-transparent focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50",
				button:
					"inline-block absolute transform translate-x-0 transition ease-in-out duration-200",
				buttonChecked:
					"inline-block absolute transform translate-x-full transition ease-in-out duration-200",
				checkedPlaceholder: "inline-block",
				uncheckedPlaceholder: "inline-block",
			},
			classes: {
				wrapper:
					"bg-gray-100 rounded-full border-2 border-transparent focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50",
				wrapperChecked: "bg-blue-500 rounded-full",
				wrapperDisabled:
					"bg-gray-100 rounded-full border-2 border-transparent focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50",
				wrapperCheckedDisabled: "bg-blue-500",
				button:
					"h-5 w-5 rounded-full bg-white shadow flex items-center justify-center text-gray-400 text-xs",
				buttonChecked:
					"h-5 w-5 rounded-full bg-white shadow flex items-center justify-center text-blue-500 text-xs",
				checkedPlaceholder:
					"rounded-full w-5 h-5 flex items-center justify-center text-gray-400 text-xs",
				uncheckedPlaceholder:
					"rounded-full w-5 h-5 flex items-center justify-center text-gray-400 text-xs",
			},
			variants: {
				danger: {
					wrapperChecked: "bg-red-500 rounded-full",
					wrapperCheckedDisabled: "bg-red-500 rounded-full",
				},
				success: {
					wrapperChecked: "bg-green-500 rounded-full",
					wrapperCheckedDisabled: "bg-green-500 rounded-full",
				},
				box: {
					wrapper:
						"bg-gray-100 rounded-sm border-2 border-transparent focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50",
					wrapperChecked: "bg-blue-500 rounded-sm",
					wrapperCheckedDisabled: "bg-blue-500 rounded-sm",
					button:
						"h-6 w-6 rounded-sm bg-white shadow flex items-center justify-center text-gray-400 text-xs",
					buttonChecked:
						"h-6 w-6 rounded-sm  bg-white shadow flex items-center justify-center text-blue-500 text-xs",
					checkedPlaceholder:
						"rounded-sm w-6 h-6 flex items-center justify-center text-gray-400 text-xs",
					uncheckedPlaceholder:
						"rounded-sm w-6 h-6 flex items-center justify-center text-gray-400 text-xs",
				},
			},
		},
	},
	"t-alert": {
		component: TAlert,
		props: {
			fixedClasses: {
				wrapper: "relative flex items-center p-4 border-l-4  rounded shadow-sm",
				body: "flex-grow",
				close:
					"absolute relative flex items-center justify-center ml-4 flex-shrink-0 w-6 h-6 transition duration-100 ease-in-out rounded  focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50",
				closeIcon: "fill-current h-4 w-4",
			},
			classes: {
				wrapper: "bg-blue-50 border-blue-500",
				body: "text-blue-700",
				close: "text-blue-500 hover:bg-blue-200",
			},
			variants: {
				danger: {
					wrapper: "bg-red-50 border-red-500",
					body: "text-red-700",
					close: "text-red-500 hover:bg-red-200",
				},
				success: {
					wrapper: "bg-green-50 border-green-500",
					body: "text-green-700",
					close: "text-green-500 hover:bg-green-200",
				},
			},
		},
	},
	"t-modal": {
		component: TModal,
		props: {
			fixedClasses: {
				overlay:
					"z-40  overflow-auto scrolling-touch left-0 top-0 bottom-0 right-0 w-full h-full fixed bg-opacity-50",
				wrapper: "relative mx-auto z-50 max-w-lg px-3 py-12",
				modal: "overflow-visible relative  rounded",
				body: "p-3",
				header: "border-b p-3 rounded-t",
				footer: " p-3 rounded-b",
				close:
					"flex items-center justify-center rounded-full absolute right-0 top-0 -m-3 h-8 w-8 transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50",
			},
			classes: {
				overlay: "bg-black",
				wrapper: "",
				modal: "bg-white shadow",
				body: "p-3",
				header: "border-gray-100",
				footer: "bg-gray-100",
				close: "bg-gray-100 text-gray-600 hover:bg-gray-200",
				closeIcon: "fill-current h-4 w-4",
				overlayEnterClass: "opacity-0",
				overlayEnterActiveClass: "transition ease-out duration-100",
				overlayEnterToClass: "opacity-100",
				overlayLeaveClass: "opacity-100",
				overlayLeaveActiveClass: "transition ease-in duration-75",
				overlayLeaveToClass: "opacity-0",
				enterClass: "",
				enterActiveClass: "",
				enterToClass: "",
				leaveClass: "",
				leaveActiveClass: "",
				leaveToClass: "",
			},
			variants: {
				danger: {
					overlay: "bg-red-100",
					header: "border-red-50 text-red-700",
					close: "bg-red-50 text-red-700 hover:bg-red-200 border-red-100 border",
					modal: "bg-white border border-red-100 shadow-lg",
					footer: "bg-red-50",
				},
			},
		},
	},
	"t-dialog": {
		component: TDialog,
		props: {
			fixedClasses: {
				overlay:
					"overflow-auto scrolling-touch left-0 top-0 bottom-0 right-0 w-full h-full fixed z-40 bg-opacity-50",
				wrapper: "relative mx-auto",
				modal: "overflow-visible relative ",
				close:
					"flex items-center justify-center  rounded-full absolute right-0 top-0 -m-3 h-8 w-8 transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50",
				dialog: "overflow-visible relative",
				iconWrapper:
					"flex flex-shrink-0 h-12 items-center justify-center rounded-full w-12 mx-auto",
				icon: "w-6 h-6",
				okButton:
					"block px-4 py-2 text-white transition duration-100 ease-in-out border border-transparent rounded shadow-sm focus:border-blue-600 focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed w-full max-w-xs",
				buttons: "p-3 flex space-x-4 justify-center bg-gray-100 rounded-b",
			},
			classes: {
				close: "bg-gray-100 text-gray-600 hover:bg-gray-200",
				closeIcon: "fill-current h-4 w-4",
				overlay: "bg-black",
				wrapper: "z-50 max-w-lg px-3 py-12",
				dialog: "bg-white shadow rounded text-left",
				body: "p-3 space-y-3",
				buttons: "bg-gray-100",
				iconWrapper: "bg-gray-100",
				icon: "text-gray-500",
				content: "w-full flex justify-center flex-col",
				titleWrapper: "",
				title: "text-lg font-semibold text-center break-all",
				textWrapper: "text-left w-full",
				text: "",
				cancelButton:
					"block px-4 py-2 transition duration-100 ease-in-out bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-100 focus:border-gray-100 focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed w-full max-w-xs",
				okButton: "bg-blue-600 hover:bg-blue-700",
				inputWrapper: "mt-3 flex items-center space-x-3",
				input:
					"block w-full px-3 py-2 text-black placeholder-gray-400 transition duration-100 ease-in-out bg-white border border-gray-300 rounded shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed w-full",
				select:
					"block w-full px-3 py-2 text-black placeholder-gray-400 transition duration-100 ease-in-out bg-white border border-gray-300 rounded shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50  disabled:opacity-50 disabled:cursor-not-allowed w-full",
				radioWrapper: "flex items-center space-x-2",
				radio:
					"text-blue-500 transition duration-100 ease-in-out border-gray-300 shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0  disabled:opacity-50 disabled:cursor-not-allowed",
				radioText: "",
				checkboxWrapper: "flex items-center space-x-2",
				checkbox:
					"text-blue-500 transition duration-100 ease-in-out border-gray-300 rounded shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0  disabled:opacity-50 disabled:cursor-not-allowed",
				checkboxText: "",
				errorMessage: "text-red-500 block text-sm",
				busyWrapper:
					"absolute bg-opacity-50 bg-white flex h-full items-center justify-center left-0 top-0 w-full",
				busyIcon: "animate-spin h-6 w-6 fill-current text-gray-500",
				overlayEnterClass: "opacity-0",
				overlayEnterActiveClass: "transition ease-out duration-100",
				overlayEnterToClass: "opacity-100",
				overlayLeaveClass: "opacity-100",
				overlayLeaveActiveClass: "transition ease-in duration-75",
				overlayLeaveToClass: "opacity-0",
				enterClass: "",
				enterActiveClass: "",
				enterToClass: "",
				leaveClass: "",
				leaveActiveClass: "",
				leaveToClass: "",
			},
			variants: {
				danger: {
					overlay: "bg-red-100",
					okButton: "bg-red-500 hover:bg-red-600",
					iconWrapper: "bg-red-50",
					buttons: "bg-red-50",
					icon: "text-red-500",
				},
				indigo: {
					overlay: "bg-indigo-100",
					okButton: "bg-indigo-500 hover:bg-indigo-600",
					iconWrapper: "bg-indigo-50",
					buttons: "bg-indigo-50",
					icon: "text-indigo-500",
				},
				horizontal: {
					body: "p-3 flex space-x-3",
					title: "text-lg font-semibold",
				},
			},
		},
	},
	"t-pagination": {
		component: TPagination,
		props: {
			classes: {
				wrapper: "table border-collapse text-center bg-white mx-auto shadow-sm",
				element: "w-8 h-8 border border-gray-200 table-cell hover:border-blue-100",
				activeElement:
					"w-8 h-8 border border-gray-200 border-blue-500 table-cell hover:border-blue-600",
				disabledElement: "w-8 h-8 border border-gray-200 table-cell",
				ellipsisElement: "w-8 h-8 border border-gray-200 hidden md:table-cell",
				activeButton:
					"bg-blue-500 w-full h-full text-white hover:bg-blue-600 transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50",
				disabledButton:
					"opacity-25 w-full h-full cursor-not-allowed transition duration-100 ease-in-out",
				button:
					"hover:bg-blue-100 w-full h-full transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50",
				ellipsis: "",
			},
			variants: {
				rounded: {
					wrapper: "bg-white mx-auto text-center flex space-x-2",
					element: "w-8 h-8 rounded-full",
					activeElement: "w-8 h-8 rounded-full",
					disabledElement: "w-8 h-8 rounded-full",
					ellipsisElement: "w-8 h-8 rounded-full hidden md:inline",
					activeButton:
						"border border-blue-600 bg-blue-600 w-full h-full rounded-full text-white hover:bg-blue-700 transition duration-100 ease-in-out focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 focus:outline-none",
					disabledButton:
						"border border-gray-200  opacity-25 w-full h-full rounded-full cursor-not-allowed transition duration-100 ease-in-out",
					button:
						"border border-gray-200 hover:bg-gray-100 hover:border-gray-100 rounded-full w-full h-full transition duration-100 ease-in-out focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 focus:outline-none",
					ellipsis: "",
				},
			},
		},
	},
};
