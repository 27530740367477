import { axiosInstance } from '@/config/axios';
import { set, del } from 'vue';

export default {
    namespaced: true,
    state: {
        messages: [],
        messagesReady: true,
        messagesMeta: {
            messagesOffset: 0,
            messagesLimit: 20
        },
        selectedFeed: 0,
        chats: [],
        chatsReady: true,
        excludedChats: {},
        chatsLimit: 20
    },
    getters: {
        messages: state => state?.messages,
        messagesReady: state => state?.messagesReady,
        chats: state => state?.chats,
        chatsReady: state => state?.chatsReady,
        excludedChats: state => state?.excludedChats,
        excludedChatIds: (state, getters) => [...Object.keys(getters?.excludedChats)].map(id => Number(id)),
        excludedChatsCounter: (state, getters) => getters?.excludedChatIds?.length,
        messagesOffset: state => state?.messagesMeta?.messagesOffset,
        messagesLimit: state => state?.messagesMeta?.messagesLimit,
        chatsLimit: state => state?.chatsLimit
    },
    mutations: {
        messages: (state, messages) => {
            state.messages = messages
        },
        messagesReady: (state, messagesReady) => {
            state.messagesReady = messagesReady
        },
        chats: (state, chats) => {
            state.chats = chats
        },
        chatsReady: (state, chatsReady) => {
            state.chatsReady = chatsReady
        },
        selectFeed: (state, id) => {
            state.selectedFeed = id
        },
        excludeChat: (state, chat) => {
            set(state.excludedChats, chat?.['tg_id'], chat)
            set(state.messages, state.messages.filter(message => {
                return message?.['chat_id'] !== chat?.['tg_id'];
            }))
        },
        unexcludeChat: (state, chat) => {
            del(state.excludedChats, chat?.['tg_id'])
        },
        messagesOffset: state => {
            set(state.messagesMeta, 'messagesOffset', state?.messagesMeta?.messagesOffset + 20)
        },
        resetMessagesOffset: state => {
            set(state.messagesMeta, 'messagesOffset', 0)
        },
    },
    actions: {
        async pullMessages ({ commit, getters, state, dispatch, rootGetters }, payload)  {
            const signal = payload?.signal
            let searchQuery = payload?.query
            let includedChatIDs = []
            if (state.selectedFeed && state.selectedFeed !== '0') {
                await dispatch('sources/fetchSources', {feedId: state.selectedFeed, limit: 100}, {root:true})                
                let sourceList = rootGetters['sources/sources'].data
                sourceList.forEach(source => {
                    if (source.type === "telegram") includedChatIDs.push(source.external_id)
                })                
                try {
                    for (let source in sourceList) {
                        if (source.type === "telegram") includedChatIDs.push(source.external_id)
                    }
                } catch (err) {console.debug(err)}
            }
            
            let excludedChatIDs = []
            commit('messagesReady', false)
            if ('exclude_chat_id' in searchQuery) {
                let { exclude_chat_id, ...searchQueryWithoutExcludeChatID } = searchQuery
                searchQuery = searchQueryWithoutExcludeChatID
		excludedChatIDs = exclude_chat_id
            }
            let queryString = new URLSearchParams({
                ...searchQuery,
                limit: getters.messagesLimit
            }).toString()
            for (const excludedChatID of excludedChatIDs) {
                queryString = queryString?.concat('&exclude_chat_id=', excludedChatID)
            }
            for (const includedChatID of includedChatIDs) {
                queryString = queryString?.concat('&chat_id=', includedChatID)
            }
            axiosInstance()
                .then(axios => {
                    return axios.get('gw/tgcp/messages?'.concat(queryString), {
                        signal
                    })
                })
                .then(response => {
                    const data = response?.data
                    if (Array.isArray(data)) {
                        commit('messages', data)
                    }
                })
                .finally(() => {
                    commit('messagesReady', true)
                })
        },
        pullChats: ({ commit, getters }, payload) => {
            const searchQuery = {
                broadcast: true,
                search: payload?.query?.search,
                limit: getters?.chatsLimit
            }
            const queryString = new URLSearchParams(searchQuery).toString()
            commit('chatsReady', false)
            axiosInstance()
                .then(axios => {
                    return axios.get('gw/tgcp/chats?'.concat(queryString), {
                        signal: payload?.signal
                    })
                })
                .then(response => {
                    const data = response?.data
                    if (Array.isArray(data)) {
                        commit('chats', data)
                    }
                })
                .finally(() => {
                    commit('chatsReady', true)
                })
        }
    }
}
