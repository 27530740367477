import { defaultAxios } from "@/config/axios";

export default {
	namespaced: true,
	state: { accessToken: "" },
	getters: {
		accessToken: ({ accessToken }) => accessToken,
	},
	mutations: {
		setAccessToken(state, payload = "") {
			state.accessToken = payload;
		},
	},
	actions: {
		async signIn({ commit }, { email, password }) {
			const {
				data: { access_token },
			} = await defaultAxios({ withError: true }).post("auth/login", {
				email: email.trim(),
				password: password.trim(),
			});

			commit("setAccessToken", access_token);
		},
		async refreshAccessToken({ commit }) {
			const {
				data: { access_token },
			} = await defaultAxios().post("auth/refresh");

			commit("setAccessToken", access_token);
		},
		async signOut({ commit }) {
			await defaultAxios().post("auth/logout");
			commit("setAccessToken", "");
		},
	},
};
